$(function () {
    //cookie 操作
    var Cookies = {
        // 设置cookie
        setCookie: function (cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        // 获取cookie
        getCookie: function (cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        // 删除cookie
        deleteCookie: function (cname) {
            document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
    };

    /*监听 Livewire 事件 实现主题切换*/
    Livewire.on('switchTheme', theme => {
        document.body.setAttribute('data-theme',theme);
    });

    //导航栏切换状态
    switch (Cookies.getCookie('sidebarShow')) {
        case 'true':
            $('#mini-button').prop('checked', 'true');
            break;
        case 'false':
            $('#mini-button').removeAttr('checked');
            break;
        default:
            break;
    }

    function switchSidebar() {
        if ($('.header-mini-btn input[type="checkbox"]').prop("checked")) {
            $(".sidebar-nav").removeClass("mini-sidebar");
            $(".sidebar-menu ul ul").css("display", "none");
        } else {
            $(".sidebar-item.sidebar-show").removeClass("sidebar-show");
            $(".sidebar-menu ul").removeAttr("style");
            $(".sidebar-nav").addClass("mini-sidebar");
        }
    }



    $("#mini-button").on("click", function () {
        var sidebarShow = Cookies.getCookie('sidebarShow');
        Cookies.setCookie('sidebarShow',changeSidebarShow(sidebarShow));
        $(".sidebar-nav").addClass("animate-nav");
        switchSidebar();
    });
    function changeSidebarShow(value) {
        if (value == 'false') {
            return 'true';
        } else {
            return 'false';
        }
    }


    //菜单收缩后操作
    $(document).on("mouseenter", ".mini-sidebar .sidebar-menu ul:first>li,.mini-sidebar .flex-bottom ul:first>li", function () {
        var t = 2, i = $(this);
        0 != i.parents(".flex-bottom").length && (t = -3);
        var a = $(".sidebar-popup.second");

        0 == a.length &&
        $("body").append("<div class='second sidebar-popup sidebar-menu-inner text-sm'><div></div></div>"),
            $(".sidebar-popup.second>div").html(i.html()),
            a.show();

        var o = i.offset().top - $(window).scrollTop() + t,
            s = $(window).height() - $(".sidebar-popup.second>div").height();

        s - o <= 0 && (o = s >= 0 ? s - 8 : 0);
        a.stop().animate({top: o}, 50);
        i.one("mouseleave", function () {
            $(".sidebar-nav").hasClass("mini-sidebar") && $(".sidebar-scroll").innerHeight() > $(".sidebar-menu").innerHeight() ? $(".mini-sidebar .sidebar-menu").one("mouseleave", function () {
                a.hide()
            }) : a.hide()
        })
    });

    //菜单搜索后 二级菜单鼠标事件
    $(document).on("mouseenter", ".second.sidebar-popup", function () {
        var t = $(".sidebar-popup.second");
        t.show(),$(this).one("mouseleave", function () {
            t.hide()
        });
        //鼠标经过时显示三级菜单
        $(this).find('.sidebar-item').on("mouseenter", function () {
            var number = $(this).children().length;
            if(number > 1){
                $(this).siblings().children('ul').hide();
                $(this).children('ul').show();
            }
        });
        //鼠标离开时隐藏三级菜单
        $(this).find('.sidebar-item').on("mouseleave", function () {
            $(this).children('ul').hide();
        });
    });

    function slideMenu(t) {
        $(".sidebar-nav").hasClass("mini-sidebar") ||
        (t.parent("li").siblings("li").removeClass("sidebar-show").children("ul").slideUp(200),
            "none" == t.nextAll("ul").css("display") ? (t.nextAll("ul").slideDown(200),
                t.parent("li").addClass("sidebar-show").siblings("li").removeClass("sidebar-show")) : (t.nextAll("ul").slideUp(200),
                t.parent("li").removeClass("sidebar-show")))
    };
    $(document).on("click", ".sidebar-menu-inner .sidebar-more,.sidebar-menu-inner a", function () {
        slideMenu($(this))
    });

    $("#sidebar-switch").on("click", function () {
        $("#sidebar").removeClass("mini-sidebar")
    });

    var headerView = function () {
        var scroll = $(window).scrollTop();
        if (scroll <= 50) {
            $("body").removeClass("compact");
        } else {
            $("body").addClass("compact");
        }
    };
    headerView();
    $(window).scroll(function () {
        headerView();
    });

    /*返回页头*/
    function initTopButton() {
        var $ = jQuery;
        $("#back-top").hide();
        $(function () {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 50) {
                    $('#back-top').fadeIn();
                } else {
                    $('#back-top').fadeOut();
                }
            });
            $('#back-top a').click(function () {
                $('body,html').animate({scrollTop: 0}, 800);
                return false;
            });
        });
    };
    initTopButton();

    $('#copy-date').text((new Date).getFullYear());

    setTimeout(function () {
        $("img.lazyload").lazyload({
            rootMargin: "100px 0px 300px 0px", //离边距多远进行加载
            threshold:0
        });
    },50);

    $(window).scroll(function () {
        if ($(this).scrollTop() > 600) {
            $('#backtotop').fadeIn(500);
        } else {
            $('#backtotop').fadeOut(500);
        }
    });
    $('#backtotop').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });

    /*实现左侧边栏根据栏目定位导航*/
    $('.sidebar-item').each(function() {
        if($(this).children('a').hasClass('active')){
            $(this).parents('.sidebar-item').first().addClass('sidebar-show').parents('.sidebar-item').first().addClass('sidebar-show');
        }
    });

});






